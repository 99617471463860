<template>
    <header id="header">
        <div ref="topBar" class="top-bar">
            <div class="topBarLeft">
                <div class="mr-2">
                    <a href="mailto://kontakt@ro-bot.pl">
                        <i class="fa fa-envelope mr-1"/> kontakt@ro-bot.pl
                    </a>
                </div>
                <div class="ml-2 d-flex flex-row align-items-center">
                    <a href="ts3server://ro-bot.net">
                        <i class="fab fa-teamspeak mr-1"/> RO-BOT.net
                    </a>
                </div>
            </div>
            <div class="topBarRight">
                <change-locale/>
            </div>
        </div>

        <div :class="[fixed ? 'visible' : '']" :style="'height: ' + navBarHeight + 'px'" class="filler"></div>

        <scroll-header ref="navBar" :fixed.sync="fixed" :threshold-in="navBarHeight + topBarHeight"
                       :threshold-out="topBarHeight">
            <b-navbar class="main-nav" toggleable="lg" type="dark">
                <b-navbar-brand :to="{name: 'home'}" href="#"><img :src="$store.getters.img('/assets/images/logo-header.png')"
                                                                   class="logo"/>
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <!-- Right aligned nav items -->

                    <b-navbar-nav>
                        <b-nav-item
                                href="#"
                                v-scroll-to="{  el: '#header', offset: 0}"
                        >
                            <i class="fa fa-home"/> {{ $t('header.home') }}
                        </b-nav-item>
                        <b-nav-item
                                href="#"
                                v-scroll-to="{  el: '#about-us', offset: -240}"
                        >
                            <i class="fa fa-info"/> {{ $t('header.info') }}
                        </b-nav-item>
                        <b-nav-item
                                href="#"
                                v-scroll-to="{  el: '#products', offset: -100}"
                        >
                            <i class="fa fa-robot"/> {{ $t('header.products') }}
                        </b-nav-item>
                        <b-nav-item
                                href="#"
                                v-scroll-to="{  el: '#news', offset: -100}"
                        >
                            <i class="fa fa-newspaper"/> {{ $t('header.news') }}
                        </b-nav-item>

                        <div class="nav-buttons">
                            <b-nav-item class="button-nav" href="https://panel.ro-bot.net" target="_blank">
                                <button class="btn primary-button">
                                    <i class="fa fa-tachometer-alt mr-2 icon"/> {{ $t('header.panel') }}
                                </button>
                            </b-nav-item>
                            <b-nav-item class="button-nav" href="https://docs.ro-bot.net" target="_blank">
                                <button  class="btn primary-button">
                                    <i class="fa fa-book mr-2 icon"/> {{ $t('header.docs') }}
                                </button>
                            </b-nav-item>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </scroll-header>
    </header>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            fixed: false,
            navBarHeight: 0,
            topBarHeight: 0,
            online: 0,
            max: 0,
        };
    },
    methods: {
        observer() {
            this.topBarHeight = this.$refs.topBar.clientHeight;
            this.navBarHeight = this.$refs.navBar.$el.clientHeight;
        },
        redirect(url, isBlank) {
            if (isBlank)
                window.open(url, '_blank');
            else
                window.open(url, '_self');
        }
    },
    mounted() {
        const resizeObserver = new ResizeObserver(this.observer);
        resizeObserver.observe(this.$refs.topBar);
        resizeObserver.observe(this.$refs.navBar.$el);
    },
}
</script>

<style lang="scss" scoped>
.filler {
    display: none;
}

.filler.visible {
    display: block;
}
</style>