import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    getters: {
        img: () => (imgPath) => {
            return imgPath;
        }
    }
});