<template>
  <back-to-top bottom="55px" right="100px" visibleoffset="200">
    <button class="btn back-to-top primary-button"><i class="fa fa-arrow-up"/></button>
  </back-to-top>
</template>

<script>
import BackToTop from 'vue-backtotop'

export default {
  name: "BackToTopButton",
  components: { BackToTop }
}
</script>

<style scoped>

</style>