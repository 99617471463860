<template>
    <footer>
        <div class="footer-info container-fluid">
            <div class="row">
                <div class="offset-xl-1 col-xl-3 col-sm-6 order-sm-1 col-12 col-info">
                    <h3><i class="fa fa-building mr-1"/> {{ $t('footer.info.title') }}</h3>
                    <div class="content flex-column">
                        <div><i class="fa fa-sign mr-2"/> ThelHost.com</div>
                        <div><i class="fa fa-map-marker mr-3"/> 16 Rustic Avenue, SW16 6PD, London</div>
                        <div><i class="fa fa-phone mr-2"/> +44 07454585335</div>
                        <div><i class="fa fa-info-circle mr-2"/> UTR: 7922843029</div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-12 order-sm-3 col-12 col-info footer-payments">
                    <h3><i class="fa fa-money-bill-alt"/> {{ $t('footer.payments') }}</h3>
                    <img src="/assets/images/payments.png">
                </div>
                <div class="col-xl-3 order-xl-3 col-sm-6 order-sm-2 col-12 col-info">
                    <h3><i class="fa fa-eye mr-1"/> {{ $t('footer.watchUs') }}</h3>
                    <div class="content" style="overflow: auto">
                        <div id="fb-root"></div>
                        <div data-href="https://www.facebook.com/robotpl" class="fb-page" data-adapt-container-width="true"
                             data-hide-cover="false" data-show-facepile="true" data-small-header="false">
                            <div class="fb-xfbml-parse-ignore">
                                <blockquote cite="https://www.facebook.com/robotpl"><a
                                    href="https://www.facebook.com/robotpl"
                                    >Aplikacja RO-BOT</a></blockquote>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img :src="$store.getters.img('/assets/svg/wave.svg')" class="wave"/>
        <div class="copyrights container-fluid">
            <span>© {{ new Date().getFullYear() }} RO-BOT.net</span>
            <span>Made by <a class="primary-link" href="http://razormeister.pl" target="_blank">RazorMeister</a></span>
        </div>

        <cookie-law theme="dark-lime" buttonText="OK!">
            <div slot="message">
                {{ $t('cookieInfo') }}
            </div>
        </cookie-law>
    </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
    name: "Footer",
    components: { CookieLaw },
    mounted() {
        this.$nextTick(() => {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = "//connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.4&appId=244261725605652";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        });
    }
}
</script>

<style lang="scss" scoped>
</style>