<template>
    <div class="flags">
        <img id="pl-flag" @click="setLang('pl')" data-lang-type="pl" class="flag" :class="[$i18n.locale === 'pl' ? 'active' : 'inactive']" src="/assets/flags/pl-flag.png" alt="PL flag">
        <img id="uk-flag" @click="setLang('en')" data-lang-type="eng" class="flag" :class="[$i18n.locale === 'en' ? 'active' : 'inactive']" src="/assets/flags/uk-flag.png" alt="UK flag">
    </div>
</template>

<script>
    import Vue from "vue";
    export default {
        name: 'locale-changer',
        methods: {
            setLang(code) {
                Vue.$cookies.set('lang', code);
                window.location.reload(true);
            }

        }
    }
</script>

<style scoped>
    .flags {
        padding: 5px;
        text-align: center;
    }

    .flag {
        width: 20px;
        margin-right: 10px;
        cursor: pointer;
        -webkit-box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.75);
        box-shadow: 10px 10px 20px -6px rgba(0,0,0,0.75);
        border-radius: 10px;
    }

    .flags .active {
        border: 1px solid #ffc107;
    }

    .flags .inactive {
        opacity: .6;
        transition: opacity .2s ease-in-out;
    }

    .flags .inactive:hover {
        opacity: 1;
    }
</style>