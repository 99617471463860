window.BASE_PATH = '';

import Vue from 'vue';
import { store } from './store';
import App from './App.vue';
import {BootstrapVue, IconsPlugin} from "bootstrap-vue";
import VueNumber from 'vue-number-animation';
import { router } from './router';
import Particles from "particles.vue";
import Skeleton from 'vue-loading-skeleton';
import VueTypedJs from 'vue-typed-js'
import "./fontawesome/css/all.min.css";
import VueProgressiveImage from 'vue-progressive-image';
import VueApexCharts from 'vue-apexcharts';
import Vuelidate from 'vuelidate';
import i18n from './i18n';
import VueScrollTo from 'vue-scrollto';
import VueWaypoint from 'vue-waypoint';
import VueFbCustomerChat from 'vue-fb-customer-chat';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import "./assets/styles.scss";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueNumber);
Vue.use(Particles);
Vue.use(Skeleton);
Vue.use(VueTypedJs);
Vue.use(require('vue-moment'));
Vue.use(VueProgressiveImage);
Vue.use(VueApexCharts);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(VueWaypoint);
Vue.use(VueFbCustomerChat, {
  page_id: 1069143943281504, //  change 'null' to your Facebook Page ID,
  theme_color: '#ff9000', // theme color in HEX
  locale: 'pl_PL', // default 'en_US'
});

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts);

Vue.component('BackToTopButton', require('./components/BackToTopButton.vue').default);
Vue.component('ScrollHeader', require('./components/ScrollHeader.vue').default);
Vue.component('Header', require('./components/Header.vue').default);
Vue.component('Footer', require('./components/Footer.vue').default);
Vue.component('PageHero', require('./components/PageHero.vue').default);
Vue.component('Counter', require('./components/Counter.vue').default);
Vue.component('ChangeLocale', require('./components/ChangeLocale.vue').default);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
