import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    base: '/',
    linkExactActiveClass: "active", // active class for *exact* links.
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {
                title: 'Strona główna',
            },
            component: () => import('./views/Home.vue'),
        },
        {
            path: '*',
            name: 'error404',
            meta: {
                title: 'Error 404',
            },
            component: () => import('./views/Error404.vue'),
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (to.meta) {
        document.title = "RO-BOT » " + to.meta.title;
    }

    return next();
});