<template>
    <div id="app" class="wrapper" style="overflow: hidden">
        <div :class="[windowLoaded ? 'done' : '']" class="preloader">
            <div class="left"></div>
            <div class="right"></div>
            <div class="spinner">
                <b-spinner label="Loading..."></b-spinner>
                <h3>{{ $t('loading') }}</h3>
            </div>
        </div>
        <div v-if="windowLoaded">
            <Header/>
            <div :style="[fixedFooter ? 'margin-bottom: ' + footerHeight + 'px' : '']" class="main-wrapper">
                <transition mode="out-in" name="fade1">
                    <router-view/>
                </transition>
            </div>
            <Footer ref="footer" :class="[fixedFooter ? 'footer-fixed' : '']"/>
            <back-to-top-button/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            windowLoaded: false,
            footerHeight: 20,
            fixedFooter: false
        }
    },
    methods: {
        observer() {
            this.footerHeight = this.$refs.footer.$el.clientHeight;
        }
    },
    async mounted() {
        window.addEventListener("load", () => {
            document.body.classList.add("loaded");
            this.windowLoaded = true;

            this.$nextTick(() => {
                const resizeObserver = new ResizeObserver(this.observer);
                resizeObserver.observe(this.$refs.footer.$el);
            });
        });
    }
}
</script>

<style>

</style>
